<template>
<div>
  <DataTable :value="studentsOldAndNewRatings">
    <Column field="barcode" header="Barcode"></Column>
    <Column header="FullName">
      <template #body="{data}">
        {{ data.last_name }} {{ data.first_name }} {{ data.middle_name }}
      </template>
    </Column>
    <Column header="StudentEp">
      <template #body="{data}">
        {{ data.education_speciality_code }} {{ data.education_speciality_name }} {{ data.admission_year }}
      </template>
    </Column>
    <Column field="semester" header="Semester"></Column>
    <Column field="old_moodle_discipline" header="OldMoodleDiscipline"></Column>
    <Column header="FrontDiscipline">
      <template #body="{data}">
          <select class="form-control form-select"
                  @input="changeDiscipline($event)">
            <option
                v-for="(item, index) in [{education_discipline_id: 0, education_discipline_name: 'Выберите дисциплину'}, ...data.front_discipline]"
                :value="item.id"
                :key="index">{{ item.education_discipline_id }} {{ item.education_discipline_name }}
            </option>
          </select>
    </template>
    </Column>
    <Column field="total" header="Total"></Column>
    <Column header="Action">
      <template #body>
        <div class="my-1">
          <Button label="Сохранить" icon="pi pi-user" class="p-button-secondary"
                  />
        </div>
        <div class="my-1">
          <Button label="Утвердить" icon="pi pi-user" class="p-button-secondary"
                  />
        </div>
        <div class="my-1">
          <Button label="Скрыть" icon="pi pi-user" class="p-button-secondary"
                  />
        </div>
      </template>
    </Column>
  </DataTable>
</div>
</template>

<script>
// @ is an alias to /src
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: 'Home',
  components: {
  },

  data() {
  },
  computed: {
    ...mapState('oldSummerRatings', ['studentsOldAndNewRatings']),
  },

  methods: {
    ...mapActions('oldSummerRatings', ['GET_OLD_MOODLE_SUMMER_COURSE']),
    changeDiscipline(e){
      console.log(e.target.value)
    }
  },
  async mounted() {
    this.GET_OLD_MOODLE_SUMMER_COURSE()
  }
}
</script>
